import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  width: 1700px;
  height: 1000px;
  position: fixed;
  bottom: 1px;

  .baseball-number-reveal {
    width: 200px;
    height: 200px;
    position: fixed;
    bottom: 13%;
  }

  canvas {
    opacity: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    &.pitch-intro {
      opacity: 1;
      height: auto;
      width: 214px;
      top: 40rem;
      left: 1rem;
      margin: 0 auto;
    }

    &.pitch-outro {
      width: 300px;
      height: 300px;
      top: 630px;
      margin: 0 auto;
    }

    &.pitch-intro-box {
      opacity: 1;
      height: 300px;
      width: auto;
      position: absolute;
      top: 57%;
      margin: 0 auto;
      z-index: -1;
    }

    &.pitch-intro-ball,
    &.pitch-out,
    &.rolling-red-ball {
      width: 100px;
      height: 412px;
      top: 409px;
      margin: 0 auto;
    }

    &.rolling-red-ball {
      opacity: 1;
      visibility: hidden;
      z-index: 4;
      margin: 0 auto;
    }

  }

  .fly {
    animation: flyup 1s forwards;
  }

  .flystatic {
    animation: flyupstatic 1s forwards;
  }

  @keyframes flyup {
    100% {
      margin-top: -200%;
    }
  }

  @keyframes flyupstatic {
    100% {
      margin-bottom: 200%;
    }
  }

  .stopped-ball {
    position: absolute;
    z-index: 4;
    width: 90px;
    height: 90px;
    top: unset;
    right: unset;
    left: 50%;
    bottom: 0;
    margin-left: -45px;
    margin-bottom: 185px;
    visibility: hidden;
  }
`;

export const Number = styled.div`
  display: flex;
  font-family: "Varsity Team Bold" !important;
  font-size: 53px;
  color: rgb(36, 46, 66);
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  line-height: normal;
  text-align: left;
  margin: auto;
  position: absolute;
  bottom: 185px;
  left: 50%;
  z-index: 2;
  width: 100px;
  height: 100px;
  transform: translateX(-50%);
  margin-left: -4px;
  align-items: center;
  justify-content: center;
  text-shadow: -2px 0 #ffffff, 0 2px #ffffff, 2px 0 #ffffff, 0 -2px #ffffff;
  opacity: 0;
`;